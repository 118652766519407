<!--
 * @Author: 刘格优
 * @Date: 2019-11-30 20:14:11
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-13 10:33:00
 -->

<template>
  <div class="content">
    <div class="bgImg">
      <img class="cardImg"
           :src="ossurl + form.pichttp" />
      <div class="letter">
        <div class="text">
          <span class="stitle">{{ form.stitle }}</span>
          <pre class="sdesc">{{ form.sdesc }}</pre>
          <div class="record">
            <div class="voice">
              <div class="voiceimg"
                   v-show="form.conthttp"
                   @click="playVoice">
                <img class="voicebgimg"
                     src="@/assets/abt/img/yuyin1.jpg" />
                <img class="pauseimg"
                     v-show="!play"
                     src="@/assets/abt/img/pause.png" />
                <img class="playimg"
                     v-show="play"
                     src="@/assets/abt/img/play.gif" />
                <audio :src="ossurl + form.conthttp"
                       style="display:none;"
                       id="audio"></audio>
              </div>
              <div>
                <span class="autor">{{ form.autor }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStorage } from '@/lib/util'
import {
  selectOneBySno,
  DoRecord
} from '@/api/abt/customerOperation/common/index'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import initWebSocket from '@/mixins/websock'
export default {
  mixins: [initWebSocket],
  data () {
    return {
      websock: null,
      ossurl: '',
      user: '',
      suserid: '',
      empno: '',
      height: '',
      width: '',
      form: {
        // pichttp: '',
        // sno: '',
        // stitle: '',
        // sdesc: '',
        // stagcode: ''
      },
      sno: '',
      isRecord: false,
      play: false,
      rytype: ''
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.width = document.body.clientWidth
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.sno = this.$route.query.sno
    this.empno = this.$route.query.empno
    this.suserid = this.$route.query.suserid
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype
  },
  destroyed: function () {
    this.websocketclose() // 页面销毁时关闭websocket连接
  },
  mounted () {
    document.querySelector('.bgImg').style.height = `${this.height}px`
    document.querySelector('.bgImg').style.width = `${this.width}px`
    this.getData()
  },
  methods: {
    getData () {
      selectOneBySno({ btagcode: '4', sno: this.sno, flag: '1' }).then(res => {
        this.form = res.data.data
        let shareurl = `${config.redirect_uri}/greetingcard/cardshare?sno=${this.sno}&empno=${this.empno}&suserid=${this.user.userid}&rytype=${this.rytype}&type=4`
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: 2,
          btagcode: 4,
          stagcode: this.form.stagcode,
          sno: this.form.sno,
          osno: this.form.osno,
          rytype: this.rytype
        }
        let path = ''
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          if (this.$route.query.frompage == 'source') {
            path = config.redirect_uri + '/source'
          } else {
            path = config.redirect_uri
          }
        } else {
          // android
          path = window.location.href
        }
        wechatshare(
          `${this.form.autor}为您送上祝福`,
          this.form.sdesc,
          this.ossurl + this.form.pichttp,
          shareurl,
          path,
          fxstr
        )
        this.behaviorRecord()
      })
    },
    // 行为记录
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '4',
        stagcode: this.form.stagcode,
        sno: this.form.sno,
        osno: this.form.osno,
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
        // alert(id)
      })
    },
    playVoice () {
      this.play = !this.play
      var audio = document.getElementById('audio')
      if (this.play) {
        audio.play()
        var _this = this
        var timer = setInterval(function () {
          if (audio.ended) {
            _this.play = !_this.play
            clearInterval(timer)
          }
        }, 100)
      } else {
        audio.pause()
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.bgImg {
  position: relative;
  overflow: hidden;

  .cardImg {
    width: 100%;
    height: 100%;
  }

  .letter {
    background-image: url('../../../../assets/abt/img/letter.png');
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 86.5%;
    background-size: cover;
    background-repeat: no-repeat;

    .text {
      position: absolute;
      top: 20%;
      left: 15%;

      .stitle {
        font-size: 35px;
        position: absolute;
        top: 7px;
        left: 50px;
        width: 750px;
        background-color: rgba(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0);
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
      }

      .sdesc {
        font-size: 36px;
        background: transparent;
        position: absolute;
        top: 80px;
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        width: 70vw;
        text-indent: 2em;
        border: none;
        line-height: 160%;
        outline: 0;
        height: 1.8rem;
        overflow-y: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .record {
        position: absolute;
        top: 300px;

        .voice {
          display: flex;
          justify-content: flex-end;
          position: relative;
          // top 320px
          // right 50px
          // text-align right
          width: 730px;

          .voiceimg {
            position: relative;

            .voicebgimg {
              width: auto;
              height: 50px;
              position: relative;
            }

            .pauseimg {
              width: auto;
              height: 30px;
              z-index: 999;
              position: absolute;
              top: 10px;
              right: 30px;
              transform: rotateY(180deg);
            }

            .playimg {
              width: auto;
              height: 30px;
              position: absolute;
              top: 10px;
              right: 30px;
              transform: rotateY(180deg);
            }
          }

          .autor {
            font-size: 36px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
  }
}
</style>
